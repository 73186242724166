.subject_cards{
    margin-left: 7rem;
}
.commerce_card{
    margin-top: 2rem;
}
@media(max-width: 640px){
    .subject_cards{
        margin-left: 0px;
    }
    .columnn{
        display: flex;
        justify-content: center;
    }
    .commerce_card{
        margin-top: 0rem;
    }
}